/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.revision-modal .scroll-col {
  max-height: 75vh;
  overflow-y: auto;
}
