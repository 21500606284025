/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.activeIndicator {
  $indicator-size: 12px;
  border-radius: $indicator-size;
  height: $indicator-size;
  width: $indicator-size;
  margin: 0 calc($indicator-size / 2);
  display: block;

  &.active {
    background-color: #5cb85c;
  }

  &.inactive {
    background-color: #d20000;
  }
}
