/*!
 * SPDX-FileCopyrightText: 2024 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@function brightness($color) {
  @return ((red($color) * 299) + (green($color) * 587) + (blue($color) * 114)) / 1000;
}

@mixin button($color) {
  $font-color: if(brightness($color) > 128, #000000, #ffffff);
  color: $font-color;
  background-color: $color;
  &:hover {
    background-color: darken($color, 15%);
  }
}

.github {
  @include button(#444444);
}

.gitlab {
  @include button(#FA7035);
}

.google {
  @include button(#DD4B39);
}

.azure {
  @include button(#008AD7);
}

.facebook {
  @include button(#0165E1);
}

.mastodon {
  @include button(#563ACC);
}

.discord {
  @include button(#5865F2);
}

.paypal {
  @include button(#00457C);
}
