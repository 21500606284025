/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.slide-sidebar {
  --sidebar-entry-height: 44px;
  --sidebar-menu-width: 280px;
  --sidebar-separator-color: var(--bs-secondary);

  :global(body[data-bs-theme=dark]) & {
    --sidebar-separator-color: rgba(255, 255, 255, 0.2);
  }

  flex: 0 0 var(--sidebar-entry-height);
  position: relative;

  .sidebar-inner {
    height: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    position: absolute;
    z-index: 999;
    width: var(--sidebar-menu-width);
    top: 0;
    left: 0;
    transition: left 0.3s;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
    border-left: solid 1px var(--sidebar-separator-color);

    &:hover, &.show {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      left: calc(0px - var(--sidebar-menu-width) + var(--sidebar-entry-height));
    }

    background: var(--bs-body-bg);
  }
}
