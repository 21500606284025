/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.card-min-height {
  min-height: 160px;
}

.card-footer-min-height {
  min-height: 27px;
}
