/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */
.entry {
  &:hover {
    :global(.sidebar-button-icon):after {
      color: inherit;
    }
  }

  --users-online: '0';

  :global(.sidebar-button-icon):after {
    content: var(--users-online);
    position: absolute;
    right: 5px;
    bottom: 3px;
    font-size: 0.9rem;
    color: var(--bg-primary);
    line-height: 1;
  }
}
