/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.divider {
  width: 15px;
  background: var(--bs-body-bg);
  z-index: 1;
  box-shadow: 0 0 6px var(--bs-gray-400);

  display: flex;
  align-items: center;
  justify-content: center;
}

.grabber {
  cursor: col-resize;
}

.middle {
  width: 35px;
  height: 35px;
  z-index: 100000;
  position: absolute;
  border-radius: 90px;
  border: solid 1px var(--bs-gray-500);
  background: var(--bs-body-bg);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: width 150ms ease-in-out, height 150ms ease-in-out, border-radius 50ms ease-in-out, transform 50ms ease-in-out;
  transform: translateX(0);

  &.shift-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translateX(8px);
  }

  &.shift-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform: translateX(-8px);
  }

  &:hover, &.open {
    width: 135px;
    height: 50px;
    border-radius: 90px;
  }

  :global(.btn) {
    border-radius: 90px;
  }

  .buttons {
    height: 40px;
    width: 3*40px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

