/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.history-menu {
  &:global(.btn) {
    padding: 0.6rem 0.65rem;
  }
  height: 2.5rem;
  width: 2.5rem;
}
